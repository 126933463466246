import { Spinner } from '@nextui-org/react';

interface SpinnerOverlayProps {
  visible?: boolean;
}

export function SpinnerOverlay({ visible }: SpinnerOverlayProps) {
  return visible ? (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <Spinner size="lg" />
    </div>
  ) : (
    <></>
  );
}
