import { ClaimOpportunityInput, PatientResourceType } from '@/helpers/types';
import { ApiRequest } from '@/lib/api/api';
import { OpportunityQuery, queries } from '@/lib/api/opportunities.queries';

export const opportunitiesRoutes = {
  async [OpportunityQuery.ClaimPatientOpportunity](variables: {
    input: ClaimOpportunityInput;
  }): Promise<{ claimPatientResource: boolean }> {
    return await new ApiRequest().gqlMutate<{
      claimPatientResource: boolean;
    }>(
      OpportunityQuery.ClaimPatientOpportunity,
      queries.claimPatientResource,
      variables
    );
  },
  async [OpportunityQuery.LoadPatientOpportunity](variables: {
    resourceId: number;
  }): Promise<PatientResourceType[]> {
    return (
      (
        await new ApiRequest().gqlGetRaw<{
          referral: {
            patientResources: { edges: { node: PatientResourceType }[] };
          };
        }>(queries.loadPatientOpportunity, variables)
      )?.referral?.patientResources?.edges?.map(({ node }) => node) ?? []
    );
  },
};
