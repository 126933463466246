import './styles.css';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import { initApp } from './helpers/initApp';
import { Providers } from '@/components/Providers';

initApp();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Providers>
    <App />
  </Providers>
);
