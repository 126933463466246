import { LoadedScene, Params } from '@/lib/scene.types';
import { Error404 } from '@/pages/Error404/Error404';
import { combineUrl, router } from 'kea-router';
import { ROMANIZED_NUMBERS } from '@/helpers/constants';

export const emptySceneParams = {
  params: {},
  searchParams: {},
  hashParams: {},
};

export enum SceneKey {
  Conversation = 'conversation',
  ConversationSummary = 'conversation_summary',
  Cookies = 'cookies',
  Dashboard = 'dashboard',
  Error404 = 'error',
  ExternalResource = 'external_resource',
  Intake = 'intake',
  MedicalHistory = 'medical_history',
  Opportunities = 'opportunities',
  Order = 'order',
  Orders = 'orders',
  PersonalIdentifying = 'personal_identifying',
  Profile = 'profile',
  SignIn = 'sign_in',
  Survey = 'survey',
  ThankYou = 'thank_you',
}

/**
 * Titles for use in tracking a user's progress.
 * i.e. the `Stepper` component.
 *
 * The order of the flow is determined by this list
 */
export const SceneTitles: Record<string, string> = {
  // These four are part of the "official" flows
  [SceneKey.Conversation]: 'Conversation',
  [SceneKey.MedicalHistory]: 'Medical History',
  [SceneKey.PersonalIdentifying]: 'Personal Details',
  [SceneKey.ConversationSummary]: 'Finalize',
  [SceneKey.ThankYou]: 'Thank You',
};

const preserveParams = (nextUrl: string): string => {
  const routerLogic = router.findMounted();
  if (!routerLogic) {
    return nextUrl;
  }
  return combineUrl(
    nextUrl,
    routerLogic.values.searchParams,
    routerLogic.values.hashParams
  ).url;
};

export const urls: Record<string, (a?: any, b?: any) => string> = {
  [SceneKey.Intake]: () => preserveParams(`/`),
  [SceneKey.SignIn]: () => preserveParams('/sign-in'),
  [SceneKey.PersonalIdentifying]: (id: string) =>
    preserveParams(`/identifying/${id}`),
  [SceneKey.Conversation]: (id: string) =>
    preserveParams(`/conversation/${id}`),
  [SceneKey.MedicalHistory]: (id: string) => preserveParams(`/medical/${id}`),
  [SceneKey.ConversationSummary]: (id: string) =>
    preserveParams(`/conversation-summary/${id}`),
  [SceneKey.ThankYou]: (id: string) => preserveParams(`/thank-you/${id}`),
  [SceneKey.Profile]: () => preserveParams(`/profile`),
  [SceneKey.Opportunities]: () => preserveParams(`/opportunities`),
  [SceneKey.Dashboard]: () => preserveParams(`/dashboard`),
  [SceneKey.Order]: (formResponseId: string) =>
    preserveParams(`/order/${formResponseId}`),
  [SceneKey.Orders]: () => preserveParams(`/orders`),
  [SceneKey.Survey]: (id: string) => preserveParams(`/survey/${id}`),
  [SceneKey.ExternalResource]: (id: string) =>
    preserveParams(`/resource/external/claim/${id}`),
  [SceneKey.Cookies]: () => '/cookies',
};

export const preloadedScenes: Record<string, LoadedScene> = {
  [SceneKey.Error404]: {
    name: SceneKey.Error404,
    component: Error404,
    sceneParams: emptySceneParams,
  },
};

export const redirects: Record<
  string,
  | string
  | ((params: Params, searchParams: Params, hashParams: Params) => string)
> = {
  '/': () => urls.intake(),
};

export const routes: Record<string, SceneKey> = {
  [urls.intake()]: SceneKey.Intake,
  [urls.sign_in()]: SceneKey.SignIn,
  [urls.personal_identifying(':id')]: SceneKey.PersonalIdentifying,
  [urls.conversation(':id')]: SceneKey.Conversation,
  [urls.conversation_summary(':id')]: SceneKey.ConversationSummary,
  [urls.medical_history(':id')]: SceneKey.MedicalHistory,
  [urls.thank_you(':id')]: SceneKey.ThankYou,
  [urls.profile()]: SceneKey.Profile,
  [urls.opportunities()]: SceneKey.Opportunities,
  [urls.dashboard()]: SceneKey.Dashboard,
  [urls.order(':id')]: SceneKey.Order,
  [urls.orders()]: SceneKey.Orders,
  [urls.survey(':id')]: SceneKey.Survey,
  [urls.external_resource(':id')]: SceneKey.ExternalResource,
  [urls.cookies(':id')]: SceneKey.Cookies,
};

// Edit these to change the order of pages
export const orderedFlowUrls = Object.fromEntries(
  Object.keys(SceneTitles).map((route, index) => [
    ROMANIZED_NUMBERS[index],
    urls[route],
  ])
);

export const orderedStepUrlsToShow = Object.values(orderedFlowUrls).filter(
  (func) => func.name !== SceneKey.ThankYou
);
