import { ApiRequest } from '@/lib/api/api';

export const eligibilityRoutes = {
  async trigger(formResponseReferenceId: string, stage: string): Promise<any> {
    return new ApiRequest().checkPreEligibility().create({
      data: { form_response_reference_id: formResponseReferenceId, stage },
    });
  },
  async poll(taskId: string): Promise<any> {
    return new ApiRequest()
      .pollPreEligibility()
      .create({ data: { task_id: taskId } });
  },
};
