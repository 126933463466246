export enum SearchDiagnosesQueries {
  SearchDiagnoses = 'searchDiagnoses',
}

// TODO:
//  , offset: $offset, before: $before, after: $after, first: $first, last: $last) {
export const queries: Record<SearchDiagnosesQueries, string> = {
  [SearchDiagnosesQueries.SearchDiagnoses]: `query searchDiagnoses($filter: SearchDiagnosisFilterInput) {
    searchDiagnoses(filter: $filter) {
      __typename
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }`,
};
