import { RudderAnalytics } from '@rudderstack/analytics-js';

const VITE_RUDDERSTACK_API_KEY = import.meta.env.VITE_RUDDERSTACK_API_KEY;
const VITE_RUDDERSTACK_DATA_PLANE_URL = import.meta.env
  .VITE_RUDDERSTACK_DATA_PLANE_URL;

let rudderstack: RudderAnalytics | null = null;

export function initializeRudderStack(): void {
  if (!VITE_RUDDERSTACK_API_KEY || !VITE_RUDDERSTACK_DATA_PLANE_URL) {
    console.error('Rudderstack environment variables could not be found.');
    return;
  }
  if (!rudderstack) {
    rudderstack = new RudderAnalytics();
    rudderstack.load(
      VITE_RUDDERSTACK_API_KEY,
      VITE_RUDDERSTACK_DATA_PLANE_URL,
      {}
    );
  }
}

initializeRudderStack();

export default rudderstack;
