import {
  actions,
  afterMount,
  defaults,
  kea,
  reducers,
  path,
  listeners,
  beforeUnmount,
  props,
  selectors,
} from 'kea';
import cookiebot, { setAndGetCookies } from '@/helpers/cookies';
import type { cookiesLogicType } from './cookiesLogicType';

export interface CookiesLogicProps {
  forceShow?: boolean; // used in storybook
}

export const cookiesLogic = kea<cookiesLogicType>([
  props({} as CookiesLogicProps),
  path(['src', 'lib', 'cookiesLogic']),
  defaults({
    canTrack: true as boolean,
    hasResponse: true as boolean,
  }),
  actions(() => ({
    accept: true,
    deny: true,
    setHasResponse: (hasResponse: boolean) => ({ hasResponse }),
    setCanTrack: (canTrack: boolean) => ({ canTrack }),
  })),
  reducers(() => ({
    hasResponse: {
      accept: () => true,
      deny: () => true,
      setHasResponse: (_, { hasResponse }) => hasResponse,
    },
    canTrack: {
      accept: () => true,
      deny: () => false,
      setCanTrack: (_, { canTrack }) => canTrack,
    },
  })),
  listeners(() => ({
    accept: () => {
      cookiebot.submit({
        optinPreferences: true,
        optinStatistics: true,
        optinMarketing: true,
      });
      setAndGetCookies();
    },
    deny: () => {
      cookiebot.submit({
        optinPreferences: false,
        optinStatistics: false,
        optinMarketing: false,
      });
      setAndGetCookies();
    },
  })),
  selectors(() => ({
    show: [
      (s) => [(_, props) => props.forceShow, s.hasResponse],
      (forceShow, hasResponse) => {
        return forceShow || !hasResponse;
      },
    ],
  })),
  afterMount(({ cache, actions }) => {
    cache.updateStateFn = function () {
      const cookieConsent = setAndGetCookies();
      actions.setHasResponse(!!window.Cookiebot?.hasResponse);
      actions.setCanTrack(cookieConsent.canTrack);
    };
    window.addEventListener(
      'CookiebotOnDialogDisplay',
      cache.updateStateFn,
      false
    );
  }),
  beforeUnmount(({ cache }) => {
    window.removeEventListener(
      'CookiebotOnDialogDisplay',
      cache.updateStateFn,
      true
    );
  }),
]);
