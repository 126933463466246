import {
  DownloadableLinkType,
  DownloadDataInput,
  DownloadReportInput,
} from '@/helpers/types';
import { ApiRequest } from '@/lib/api/api';
import { OrderQuery, queries } from '@/lib/api/order.queries';

export const orderRoutes = {
  async downloadReport(variables: {
    input: DownloadReportInput;
  }): Promise<string> {
    return (
      await new ApiRequest().gqlMutate<DownloadableLinkType>(
        OrderQuery.DownloadReport,
        queries.downloadReport,
        variables
      )
    ).url;
  },
  async downloadData(variables: { input: DownloadDataInput }): Promise<string> {
    return (
      await new ApiRequest().gqlMutate<DownloadableLinkType>(
        OrderQuery.DownloadData,
        queries.downloadData,
        variables
      )
    ).url;
  },
};
