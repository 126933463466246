import rudderstack from '@/helpers/rudderstack';
import posthog, { Survey } from 'posthog-js';
import { SurveyResponse } from '@/helpers/types';
import { IS_DEV } from '@/helpers/constants';
import { cookiesLogic } from '@/lib/cookiesLogic';

const addEventToDataLayer = (event: string, detail?: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: event.toString(), detail });
  if (IS_DEV) {
    console.info(
      `Adding to dataLayer: {'event': ${event}, 'detail': ${detail}}`
    );
  }
};

function send(
  callback: undefined | ((...p: any[]) => void),
  eventName: string,
  eventProperties?: Record<string, any>,
  ...params: any[]
) {
  if (!cookiesLogic?.findMounted()?.values?.canTrack) {
    return;
  }
  callback?.(
    eventName,
    {
      ...eventProperties,
      // Required to link the correct PH session
      $session_id: posthog.get_session_id(),
    },
    ...params
  );
  addEventToDataLayer(eventName);
}

export const analytics = {
  page: (name: string) => {
    send(rudderstack?.page, name);
  },
  identify: (
    id: string,
    traits: Record<string, any> & {
      email?: string;
      formResponseId?: string;
    }
  ) => {
    posthog?.identify?.(id);
    send(rudderstack?.identify, id, {
      ...traits,
      isLoggedIn: true,
    });
  },
  unidentify: () => {
    rudderstack?.reset?.(true);
    posthog?.reset?.();
    send(rudderstack?.identify, '', { isLoggedIn: false });
  },
  getFeatureFlag: (flagStr: string): boolean | string | undefined => {
    return posthog.getFeatureFlag(flagStr);
  },
  user: {
    preTestGcClick: () => {
      send(rudderstack?.track, 'user:pre_test_gc_click');
    },
    postTestGcClick: () => {
      send(rudderstack?.track, 'user:post_test_gc_click');
    },
  },
  survey: {
    shown: (survey: Survey) => {
      // Naming convention required for posthog compatibility
      send(rudderstack?.track, 'survey shown', {
        $survey_id: survey.id,
      });
    },
    sent: (surveyResponse: SurveyResponse) => {
      // Naming convention required for posthog compatibility
      send(rudderstack?.track, 'survey sent', surveyResponse);
    },
  },
  form: {
    signInButtonClick: () => {
      send(rudderstack?.track, 'form:signin_button_click');
    },
    signUpButtonClick: () => {
      send(rudderstack?.track, 'form:signup_button_click');
    },
  },
  conversation: {
    // Track a user's interaction with a conversation form input.
    // Example: the "yes" and "no" parts of the `ConversationYesNoInput`.
    // Avoid logging the full values of the form input.
    formInputClick: (inputName: string, value: string) => {
      send(rudderstack?.track, 'conversation:form_input_click', {
        inputName,
        value,
      });
    },
  },
  notification: {
    notificationShown: (notificationName: string) => {
      send(rudderstack?.track, 'notification:notification_shown', {
        notificationName,
      });
    },
  },
};
