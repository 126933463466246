import { ApiRequest } from '@/lib/api/api';
import type {
  SearchDiagnosesInput,
  SearchDiagnosesResponse,
  SearchDiagnosesResponseItem,
} from '@/helpers/types';
import {
  queries,
  SearchDiagnosesQueries,
} from '@/lib/api/searchDiagnoses.queries';

export const searchDiagnosesRoutes = {
  async search(filter: SearchDiagnosesInput): Promise<SearchDiagnosesResponse> {
    const variables = {
      filter,
    };

    return (
      (
        await new ApiRequest().gqlGet<{
          edges: { node: SearchDiagnosesResponseItem }[];
        }>(
          SearchDiagnosesQueries.SearchDiagnoses,
          queries[SearchDiagnosesQueries.SearchDiagnoses],
          variables
        )
      )?.edges?.map(({ node }) => node) ?? []
    );
  },
};
