import { actions, events, kea, path, reducers, selectors } from 'kea';
import posthog from 'posthog-js';
import type { featureFlagLogicType } from './featureFlagLogicType';
import { analytics } from '@/helpers/analytics';
import { FEATURE_FLAGS } from '@/lib/featureFlags';

export type FeatureFlagsSet = {
  [flag: string]: boolean | string | undefined;
};

function generatedFeatureFlagProxy(
  cachedFeatureFlagSet: FeatureFlagsSet = {},
  refresh = false
): FeatureFlagsSet {
  if (typeof window.Proxy !== 'undefined') {
    return new Proxy(cachedFeatureFlagSet, {
      get(target, flag) {
        const flagStr = String(flag);
        if (!refresh && target?.[flagStr]) {
          return target[flagStr];
        }
        return analytics.getFeatureFlag(flagStr);
      },
    });
  }
  return cachedFeatureFlagSet;
}

export const featureFlagLogic = kea<featureFlagLogicType>([
  path(['src', 'lib', 'featureFlagLogic']),
  actions({
    setFeatureFlags: (
      flags: string[],
      variants: Record<string, string | boolean>,
      refresh: boolean
    ) => ({ flags, variants, refresh }),
  }),
  reducers(({ cache }) => ({
    featureFlags: [
      generatedFeatureFlagProxy() as FeatureFlagsSet,
      { persist: true },
      {
        setFeatureFlags: (prevFlags, { variants, refresh }) =>
          generatedFeatureFlagProxy(
            Object.assign({}, prevFlags, variants),
            refresh
          ),
      },
    ],
    receivedFeatureFlags: [
      false,
      {
        setFeatureFlags: () => true,
      },
    ],
  })),
  events(({ actions }) => ({
    afterMount: () => {
      posthog.onFeatureFlags((flag, variants) => {
        actions.setFeatureFlags(
          flag,
          variants,
          posthog.featureFlags._override_warning
        );
      });
    },
  })),
  selectors(() => ({
    showDiagnosesSearch: [
      (s) => [s.featureFlags],
      (featureFlags) => !!featureFlags?.[FEATURE_FLAGS.CHATPG_DIAG_SEARCH],
    ],
    enableEligibilityPolling: [
      (s) => [s.featureFlags],
      (featureFlags) =>
        !!featureFlags?.[FEATURE_FLAGS.CHATPG_ELIGIBILITY_POLLING],
    ],
    showNotifications: [
      (s) => [s.featureFlags],
      (featureFlags) => !!featureFlags?.[FEATURE_FLAGS.NOTIFICATIONS],
    ],
    showEmailLaterFlow: [
      (s) => [s.featureFlags],
      (featureFlags) => false, // featureFlags?.[FEATURE_FLAGS.EMAIL_LATER] === 'test',
    ],
  })),
]);
