import {
  getCookie,
  snakify,
  getJSONOrThrow,
  checkGqlResponse,
} from '@/helpers/utils';
import { gql, GraphQLClient } from 'graphql-request';
import * as Sentry from '@sentry/browser';
import { ApiMethodOptions } from '@/lib/api/api';

function getDuration(startTime: number) {
  return new Date().getTime() - startTime;
}

interface ErrorContext {
  response?: Response;
  startTime?: number;
  data?: unknown;
}

function reportError(
  error: Error,
  method: string,
  url: string,
  context: ErrorContext = {}
): void {
  // eslint-disable-next-line no-restricted-globals
  const pathname = new URL(url, location.origin).pathname;
  const { startTime, response, data = {} } = context;
  const properties = {
    pathname,
    method,
    duration: startTime ? getDuration(startTime) : null,
    status: response ? response.status : null,
  };

  Sentry.captureException(error, {
    level: 'error',
    tags: properties,
    extra: {
      ...properties,
      response,
      data,
    },
  });
}

export const genericRoutes = {
  /** Fetch data from specified URL. The result already is JSON-parsed. */
  async get<T = any>(url: string, options?: ApiMethodOptions): Promise<T> {
    const startTime = new Date().getTime();
    const csrftoken = getCookie('csrftoken');

    const response = await fetch(url, {
      signal: options?.signal,
      mode: 'cors',
      credentials: 'include',
      headers: {
        'X-CSRFToken': csrftoken,
      },
    });

    if (!response.ok) {
      const data = await getJSONOrThrow(response);
      const err = new Error('HTTP request error');
      reportError(err, 'GET', url, { response, startTime, data });
      throw { status: response.status, ...data };
    }
    return await getJSONOrThrow(response);
  },

  async update(
    url: string,
    data: any,
    options?: ApiMethodOptions
  ): Promise<any> {
    const isFormData = data instanceof FormData;
    const startTime = new Date().getTime();
    const csrftoken = getCookie('csrftoken');
    const body = isFormData ? data : JSON.stringify(snakify(data));

    const response = await fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      mode: 'cors',
      headers: {
        ...(isFormData
          ? {}
          : {
              'Content-Type': 'application/json',
            }),
        'X-CSRFToken': csrftoken,
      },
      body,
      signal: options?.signal,
    });

    if (!response.ok) {
      const data = await getJSONOrThrow(response);
      const err = new Error('HTTP request error');
      reportError(err, 'PATCH', url, { response, startTime, data });
      throw { status: response.status, ...data };
    }
    return await getJSONOrThrow(response);
  },

  async create(
    url: string,
    data?: any,
    options?: ApiMethodOptions
  ): Promise<any> {
    const isFormData = data instanceof FormData;
    const startTime = new Date().getTime();
    const csrftoken = getCookie('csrftoken');
    const body = data
      ? isFormData
        ? data
        : JSON.stringify(snakify(data))
      : undefined;

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...(isFormData
          ? {}
          : {
              'Content-Type': 'application/json',
            }),
        'X-CSRFToken': csrftoken,
      },
      body,
      signal: options?.signal,
    });

    if (!response.ok) {
      const data = await getJSONOrThrow(response);
      const err = new Error('HTTP request error');
      reportError(err, 'POST', url, { response, startTime, data });
      throw { status: response.status, ...data };
    }

    return await getJSONOrThrow(response);
  },

  async delete(url: string): Promise<any> {
    const startTime = new Date().getTime();
    const csrftoken = getCookie('csrftoken');

    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': csrftoken,
      },
    });

    if (!response.ok) {
      const data = await getJSONOrThrow(response);
      const err = new Error('HTTP request error');
      reportError(err, 'DELETE', url, { response, startTime, data });
      throw { status: response.status, ...data };
    }
    return response;
  },

  gql: {
    async get<T = any>(
      url: string,
      query: string,
      variables: Record<string, any>
    ): Promise<T> {
      const startTime = new Date().getTime();
      const csrftoken = getCookie('csrftoken');

      let response: T;
      try {
        const client = new GraphQLClient(url, {
          credentials: 'include',
          mode: 'cors',
          headers: {
            'X-CSRFToken': csrftoken,
          },
        });
        response = await client.request(
          gql`
            ${query}
          `,
          variables
        );
      } catch (e: any) {
        Sentry.captureException(e, {
          level: 'error',
          extra: { status: 400, statusText: e.message, query },
        });
        throw { status: 400, statusText: e.message, query };
      }

      const gqlErrors = checkGqlResponse(response as Record<string, any>);

      if (!gqlErrors.ok) {
        const errorResponse = {
          status: 400,
          statusText: gqlErrors.error,
        } as Response;
        const err = new Error('GraphQL mutation error');
        Sentry.captureException(err, {
          level: 'error',
          extra: { ...errorResponse, query, duration: getDuration(startTime) },
        });

        // TODO: Should create a custom Exception/Error class instead of
        //  throwing arbitrary objects for better error visibility.
        throw errorResponse;
      }

      return response;
    },
    async mutate<T = any>(
      url: string,
      query: string,
      variables: Record<string, any>
    ): Promise<T> {
      const startTime = new Date().getTime();
      const csrftoken = getCookie('csrftoken');

      let response: T;
      try {
        const client = new GraphQLClient(url, {
          credentials: 'include',
          mode: 'cors',
          headers: {
            'X-CSRFToken': csrftoken,
          },
        });
        response = await client.request(
          gql`
            ${query}
          `,
          variables
        );
      } catch (e: any) {
        // Standardize error catching with REST
        Sentry.captureException(e, {
          level: 'error',
          extra: { status: 400, statusText: e.message, query },
        });
        // TODO: Should create a custom Exception/Error class instead of
        //  throwing arbitrary objects for better error visibility.
        throw { status: 400, statusText: e.message, query };
      }

      const gqlErrors = checkGqlResponse(response as Record<string, any>);

      if (!gqlErrors.ok) {
        const errorResponse = {
          status: 400,
          statusText: gqlErrors.error,
        } as Response;
        const err = new Error('GraphQL mutation error');
        Sentry.captureException(err, {
          level: 'error',
          extra: { ...errorResponse, query, duration: getDuration(startTime) },
        });
        // TODO: Should create a custom Exception/Error class instead of
        //  throwing arbitrary objects for better error visibility.
        throw errorResponse;
      }

      return response;
    },
  },
};
