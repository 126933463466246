import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ReactNode } from 'react';
import { NextUIProvider } from '@nextui-org/react';

export function Providers({ children }: { children: ReactNode }) {
  return (
    <NextUIProvider>
      {children}
      <ToastContainer />
    </NextUIProvider>
  );
}
