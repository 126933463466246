export enum OpportunityQuery {
  ClaimPatientOpportunity = 'claimPatientResource',
  LoadPatientOpportunity = 'loadPatientOpportunity',
}

export const queries: Record<OpportunityQuery, string> = {
  [OpportunityQuery.ClaimPatientOpportunity]: `mutation claimPatientResource($input: ClaimPatientResourceInput) {
        claimPatientResource(input: $input)
      }`,
  [OpportunityQuery.LoadPatientOpportunity]: `
query referralQuery($resourceId: Int) {
  referral {
    patientResources(resourceId: $resourceId) {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          name
          resourceExternalId
          leavingPopupMessage
          footerMessage
          message
          urlInfo
          pdfInfoUrl
        }
      }
    }
  }
}`,
};
