export enum PhysicianQuery {
  CreatePhysician = 'createPhysician',
  RemovePhysician = 'removePhysician',
  SetPrimaryPhysician = 'setPrimaryPhysician',
}

export const queries: Record<PhysicianQuery, string> = {
  [PhysicianQuery.CreatePhysician]: `mutation createPhysician($input: CreatePhysicianInput) {
  createPhysician(input: $input) {
    __typename
    id
    patient {
      __typename
      id
      physicians {
        __typename
        id
        latitude
        longitude
        npi
        firstName
        lastName
        phoneNumber
        faxNumber
        organization
        address1
        address2
        zipCode
        state
        city
        isPrimary
        specialties {
          name
          id
        }
      }
    }
  }
}
      `,
  [PhysicianQuery.SetPrimaryPhysician]: `mutation setPrimaryPhysician($input: SetPrimaryPhysicianInput) {
  setPrimaryPhysician(input: $input) {
    __typename
    id
    physicians {
      __typename
      id
      latitude
      longitude
      npi
      firstName
      lastName
      phoneNumber
      faxNumber
      organization
      address1
      address2
      zipCode
      state
      city
      isPrimary
      specialties {
        name
        id
      }
    }
  }
}
  `,
  [PhysicianQuery.RemovePhysician]: `mutation removePhysician($input: RemovePhysicianInput) {
  removePhysician(input: $input) {
    __typename
    id
    patient {
      __typename
      id
      physicians {
        __typename
        id
        latitude
        longitude
        npi
        firstName
        lastName
        phoneNumber
        faxNumber
        organization
        address1
        address2
        zipCode
        state
        city
        isPrimary
        specialties {
          name
          id
        }
      }
    }
  }
}`,
};
