import {
  CreatePhysicianInput,
  PatientType,
  PhysicianType,
  RemovePhysicianInput,
  SearchPhysiciansInput,
  SearchPhysiciansResponse,
  SetPrimaryPhysicianInput,
} from '@/helpers/types';
import { ApiRequest } from '@/lib/api/api';
import { PhysicianQuery, queries } from '@/lib/api/physician.queries';

export const physicianRoutes = {
  async search(
    input: SearchPhysiciansInput
  ): Promise<SearchPhysiciansResponse> {
    return await new ApiRequest().searchDoctors().create(
      {
        data: input,
      },
      false
    );
  },
  async [PhysicianQuery.CreatePhysician](variables: {
    input: CreatePhysicianInput;
  }): Promise<PatientType> {
    return (
      await new ApiRequest().gqlMutate<{
        patient: PatientType;
      }>(PhysicianQuery.CreatePhysician, queries.createPhysician, variables)
    ).patient;
  },
  async [PhysicianQuery.RemovePhysician](variables: {
    input: RemovePhysicianInput;
  }): Promise<PatientType> {
    return (
      await new ApiRequest().gqlMutate<{
        patient: PatientType;
      }>(PhysicianQuery.RemovePhysician, queries.removePhysician, variables)
    ).patient;
  },
  async [PhysicianQuery.SetPrimaryPhysician](variables: {
    input: SetPrimaryPhysicianInput;
  }): Promise<PhysicianType[]> {
    return (
      await new ApiRequest().gqlMutate<{
        physicians: PhysicianType[];
      }>(
        PhysicianQuery.SetPrimaryPhysician,
        queries.setPrimaryPhysician,
        variables
      )
    ).physicians;
  },
};
