export enum FormResponseQuery {
  InitFormResponse = 'initFormResponse',
  UpdateQuestionValues = 'updateQuestionValues',
  FormResponseState = 'formResponseState',
  UpdateAddress = 'updateAddress',
  UpdateGeneticTesting = 'updateGeneticTesting',
  Genes = 'genes',
  UploadPhoto = 'uploadPhoto',
  DeletePhoto = 'deletePhoto',
  CompleteTestingQuestions = 'completeTestingQuestions',
  AcceptInformedConsent = 'acceptInformedConsent',
  ProcessResult = 'processResult',
  FormResponses = 'formResponses', // query for orders
  UpdateSelfReportedDiagnoses = 'updateSelfReportedDiagnoses',
  UpdateSelfReportedGeneticHistory = 'updateSelfReportedGeneticHistory',
}

export const formResponseQuery = `
      __typename
          id
          referenceId
          submittedTimestamp
          queryTags
          parsed
          result
          customerRelationshipToPatient
          orderReferenceId
          status
          conversation {
            id
          }
          selectedHpos {
            hpo {
              id
              hpoId
              name
              definition
              displayName
            }
          }
          completedTestingQuestions
          acceptedInformedConsent
          testingOptOut
          pk
          fullUrl
          referral {
            __typename
            referralType
          }
          pendingOrderUrl
          form {
            __typename
            id
            formId
            name
            displayName
            termsOfService
            optInDisclaimer
            showKitReturnAgreement
            showPreTestGcAgreement
          }
          person {
            __typename
            id
            referenceId
            email
            phone
            firstName
            lastName
          }
          patient {
            __typename
            id
            firstName
            lastName
            dateOfBirth
            biologicalSex
            parentsRelated
            hematologicMalignancy
            boneMarrowStemCellTransplant
            physicians {
              __typename
              id
              npi
              firstName
              lastName
              phoneNumber
              faxNumber
              organization
              address1
              address2
              zipCode
              state
              city
              isPrimary
              specialties {
                id
                name
              }
            }
            ethnicities {
              __typename
              id
              ethnicity
            }
          }
          shippingAddress {
            __typename
            zipCode
            country
            city
            address1
            state
            address2
            latitude
            longitude
            exUnitedStates
            zip3
          }
          facialPhotos {
            __typename
            id
            photo
          }
          fieldValues {
            __typename
            id
            value
            field {
              __typename
              id
              pk
              name
            }
          }
          opportunities {
            __typename
            pk
            id
            referralType
            patientResource {
              __typename
              pk
              id
              name
              pdfInfoUrl
              message
              leavingPopupMessage
              resourceExternalId
              footerMessage
              urlInfo
            }
          }
          questions {
            __typename
            id
            pk
            questionType
            header
            overrides
            required
            showField
            multipleValuesAllowed
            addNoneOfTheseOption
            addDontKnowOption
            addUnknownOption
            optInDisclaimer
            description
            placeholder
            regExTestPattern
            bigText
            displayedErrorMessage
            showDescriptiveTextOnOptions
            showImagesOnOptions
            sorting
            enableLogging
            field {
              __typename
              id
              pk
              name
              displayName
              fieldType
              visibility
            }
            options {
              __typename
              predefinedValue {
                __typename
                id
                name
                displayName
                description
                imageUrl
              }
            }
          }
          selfReportedTests {
            __typename
            id
            testType
            result
            panelName
            testingYear
            variants {
              __typename
              cdnaChange
              geneSymbol
              pathogenicity
            }
          }
          selfReportedDiagnoses {
            __typename
            id
            diagnosis {
              __typename
              id
              monarchId
              name
              displayName
              description
            }
          }
          selfReportedGeneticHistory {
            __typename
            id
            relative
            geneSymbol
            pathogenicity
          }
`;

export const queries: Record<FormResponseQuery, string> = {
  [FormResponseQuery.InitFormResponse]: `mutation initFormResponse($formName: String!, $queryTags: JSONString!, $isAnonymous: Boolean) {
      initFormResponse(formName: $formName, queryTags: $queryTags, isAnonymous: $isAnonymous) {
        __typename
        formResponse {
          __typename
          id
          referenceId
        }
      }
    }`,
  [FormResponseQuery.UpdateQuestionValues]: `mutation updateQuestionValues($input: UpdateQuestionValuesInput) {
      updateQuestionValues(input: $input) {
        __typename
        id
        fieldValues {
          __typename
          id
          value
          field {
            __typename
            id
            pk
          }
        }
      }
    }`,
  [FormResponseQuery.FormResponseState]: `
      query formResponseState($formResponseId: UUID!) {
        formResponseState(formResponseId: $formResponseId) {
          ${formResponseQuery}
        }
      }`,
  [FormResponseQuery.UpdateAddress]: `mutation updateAddress($input: UpdateAddressInput) { updateAddress(input: $input) {
        __typename
        zipCode
        country
        city
        address1
        state
        address2
        latitude
        longitude
        exUnitedStates
        zip3
      }
    }`,
  [FormResponseQuery.UpdateGeneticTesting]: `mutation updateGeneticTesting($input: UpdateGeneticTestingInput!) {
    updateGeneticTesting(input: $input) {
      __typename
      formResponse {
              ${formResponseQuery}
      }
    }
  }`,
  [FormResponseQuery.Genes]: `query genes($filter: GeneFilterInput, $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  genes(filter: $filter, offset: $offset, before: $before, after: $after, first: $first, last: $last) {
    __typename
    edges {
      __typename
      node {
        __typename
        id
        name
        symbol
      }
    }
  }
}`,
  [FormResponseQuery.UploadPhoto]: `mutation uploadPhoto($input: UploadPhotoInput) {
  uploadPhoto(input: $input) {
    __typename
    ok
    formResponse {
      ${formResponseQuery}
    }
  }
}
  `,
  [FormResponseQuery.DeletePhoto]: `mutation deletePhoto($input: DeletePhotoInput) {
  deletePhoto(input: $input) {
    __typename
    ok
    formResponse {
      ${formResponseQuery}
    }
  }
}
    `,
  [FormResponseQuery.ProcessResult]: `mutation processResult($input: ProcessResultInput) {
    processResult(input: $input) {
      ${formResponseQuery}
    }
  }
`,
  [FormResponseQuery.CompleteTestingQuestions]: `mutation completeTestingQuestions($input: CompleteTestingQuestionsInput) {
    completeTestingQuestions(input: $input) {
      __typename
      id
      referenceId
      submittedTimestamp
      queryTags
      parsed
      result
      orderReferenceId
      previousOrderReferenceId
      status
      completedTestingQuestions
      testingOptOut
      acceptedInformedConsent
      pk
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      fullUrl
      pendingOrderUrl
    }
  }`,
  [FormResponseQuery.FormResponses]: `query formResponses($filter: FormResponseFilterInput, $sorting: FormResponseSortInput, $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  formResponses(filter: $filter, sorting: $sorting, offset: $offset, before: $before, after: $after, first: $first, last: $last) {
    __typename
    edges {
      __typename
      node {
        __typename
        id
        id
        pk
        referenceId
        submittedTimestamp
        result
        status
        orderReferenceId
        conversation {
          id
        }
        order {
          referenceId
          status
          statusDates
          pwnConsultLink
          pwnConsultDt
          pwnPreTestConsultDt
          pwnPreTestConsultLink
          isSponsored
          kits {
            barcode
            samples {
              id
              vcfFileAvailable
              personReferenceId
              test {
                variants {
                  findingType
                }
              }
            }
          }
          requiresGcPreTestConsult
          isConsentAccepted
          sampleType
        }
        pendingOrderUrl
        completedTestingQuestions
        form {
          __typename
          id
          formId
          name
          displayName
        }
        patient {
          __typename
          id
          firstName
          lastName
        }
      }
    }
  }
}
`,
  [FormResponseQuery.AcceptInformedConsent]: `mutation acceptInformedConsent($input: AcceptInformedConsentInput) {
    acceptInformedConsent(input: $input) {
      ${formResponseQuery}
    }
  }
  `,
  [FormResponseQuery.UpdateSelfReportedDiagnoses]: `mutation updateSelfReportedDiagnoses($input: UpdateSelfReportedDiagnosesInput!) {
    updateSelfReportedDiagnoses(input: $input) {
      __typename
      formResponse {
        selfReportedDiagnoses {
          __typename
          id
          diagnosis {
            __typename
            id
            monarchId
            name
            displayName
            description
          }
        }
      }
    }
  }
  `,
  [FormResponseQuery.UpdateSelfReportedGeneticHistory]: `mutation updateSelfReportedGeneticHistory($input: UpdateSelfReportedGeneticHistoryInput!) {
    updateSelfReportedGeneticHistory(input: $input) {
      __typename
      formResponse {
        __typename
        selfReportedGeneticHistory {
          __typename
          id
          relative
          geneSymbol
          pathogenicity
        }
      }
    }
  }
  `,
};
