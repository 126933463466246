import {
  Conversation,
  ConversationApiResponse,
  LLMType,
} from '@/helpers/types';
import { parseConversation } from '@/helpers/utils';
import { ApiRequest } from '@/lib/api/api';

export const conversationRoutes = {
  async get(
    conversation: Pick<Conversation, 'id'>
  ): Promise<ConversationApiResponse> {
    const response = await new ApiRequest().conversation(conversation.id).get();
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
  async create({
    conversation,
    llmType = LLMType.Text,
  }: {
    conversation: Pick<
      Conversation,
      'formResponseId' | 'relationshipToPatient'
    >;
    llmType?: LLMType;
  }): Promise<ConversationApiResponse> {
    const response = await new ApiRequest().conversations().create({
      data: {
        conversation,
        llmType,
      },
    });
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
  async update({
    conversation,
    requestNextQuestion = false,
    llmType = LLMType.Text,
  }: {
    conversation: Readonly<Conversation>;
    requestNextQuestion?: boolean;
    llmType?: LLMType;
  }): Promise<ConversationApiResponse> {
    if (!conversation.id) {
      throw new Error(
        `Conversation id cannot be empty on update: ${conversation.id}`
      );
    }
    const response = await new ApiRequest()
      .conversation(conversation.id)
      .update({
        data: {
          conversation,
          requestNextQuestion,
          llmType,
        },
      });
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
  async submit(
    conversation: Readonly<Conversation>
  ): Promise<ConversationApiResponse> {
    const response = await new ApiRequest()
      .conversation(conversation.id)
      .withAction('submit')
      .update({
        data: {
          conversation: {
            ...conversation,
            submitted: true,
          },
        },
      });
    return {
      ...response,
      conversation: parseConversation(response.conversation),
    };
  },
};
