import { SceneExport } from '@/lib/scene.types';
import { Button } from '@nextui-org/react';
import { A } from 'kea-router';
import { urls } from '@/lib/routes';

export const scene: SceneExport = {
  component: Error404,
};

export function Error404() {
  return (
    <div className="w-full h-screen flex justify-center items-center p-8">
      <div className="max-w-3xl w-full flex flex-col gap-2 text-center justify-center items-center">
        <h1 className="text-[10rem] font-black text-navy font-serif leading-[9rem]">
          404
        </h1>
        <p className="font-bold text-xl">Oops! This Page Could Not Be Found</p>
        <p className="text-lg font-sans">
          We are sorry but the page you requested was not found.
        </p>
        <Button
          as={A}
          href={urls.intake()}
          size="lg"
          variant="solid"
          className="font-bold bg-navy mt-3 text-white"
        >
          Go to Homepage
        </Button>
      </div>
    </div>
  );
}
