import {
  actions,
  BindLogic,
  events,
  kea,
  path,
  reducers,
  useMountedLogic,
  useValues,
} from 'kea';
import { sceneLogic } from '@/lib/sceneLogic';
import { SceneKey } from '@/lib/routes';
import { LoadedScene } from '@/lib/scene.types';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { SpinnerOverlay } from '@/components/SpinnerOverlay';
import { ReactNode } from 'react';
import { featureFlagLogic } from '@/lib/featureFlagLogic';
import type { appLogicType } from './appType';
import { userLogic } from '@/lib/userLogic';
import { cookiesLogic } from '@/lib/cookiesLogic';

const appScenes: Record<SceneKey, any> = {
  [SceneKey.Intake]: () => import('@/pages/Intake/Intake'),
  [SceneKey.SignIn]: () => import('@/pages/Auth/SignIn/SignIn'),
  [SceneKey.PersonalIdentifying]: () =>
    import('@/pages/PersonalIdentifying/PersonalIdentifying'),
  [SceneKey.Error404]: () => import('@/pages/Error404/Error404'),
  [SceneKey.Conversation]: () => import('@/pages/Conversation/Conversation'),
  [SceneKey.MedicalHistory]: () =>
    import('@/pages/MedicalHistory/MedicalHistory'),
  [SceneKey.ConversationSummary]: () =>
    import('@/pages/ConversationSummary/ConversationSummary'),
  [SceneKey.Profile]: () => import('@/pages/Profile/ProfileScene'),
  [SceneKey.Opportunities]: () => import('@/pages/Opportunities/Opportunities'),
  [SceneKey.ThankYou]: () => import('@/pages/ThankYou/ThankYou'),
  [SceneKey.Dashboard]: () => import('@/pages/Dashboard/Dashboard'),
  [SceneKey.Order]: () => import('@/pages/Order/Order'),
  [SceneKey.Orders]: () => import('@/pages/Order/Orders'),
  [SceneKey.Survey]: () => import('@/pages/Surveys/Survey'),
  [SceneKey.ExternalResource]: () =>
    import('@/pages/External/Resource/ExternalResource'),
  [SceneKey.Cookies]: () => import('@/pages/Cookies/Cookies'),
};

export const appLogic = kea<appLogicType>([
  path(['src', 'app', 'app']),
  actions({
    enableDelayedSpinner: true,
  }),
  reducers({
    showingDelayedSpinner: [false, { enableDelayedSpinner: () => true }],
  }),
  events(({ actions, cache }) => ({
    afterMount: () => {
      cache.spinnerTimeout = window.setTimeout(
        () => actions.enableDelayedSpinner(),
        1000
      );
    },
    beforeUnmount: () => {
      window.clearTimeout(cache.spinnerTimeout);
    },
  })),
]);

export function App() {
  useMountedLogic(cookiesLogic);
  useMountedLogic(featureFlagLogic);
  useMountedLogic(userLogic);
  useMountedLogic(sceneLogic({ scenes: appScenes }));

  return (
    <>
      <LoadedSceneLogics />
      <AppScene />
    </>
  );
}

function LoadedSceneLogic({ scene }: { scene: LoadedScene }): null {
  if (!scene.logic) {
    throw new Error('Loading scene without a logic');
  }
  useMountedLogic(scene.logic(scene.paramsToProps?.(scene.sceneParams)));
  return null;
}

function LoadedSceneLogics() {
  const { loadedScenes } = useValues(sceneLogic);

  return (
    <>
      {Object.entries(loadedScenes)
        .filter(([, { logic }]) => !!logic)
        .map(([key, loadedScene]) => (
          <LoadedSceneLogic key={key} scene={loadedScene} />
        ))}
    </>
  );
}

function AppScene(): JSX.Element | null {
  const { scene, activeLoadedScene, sceneParams, loadedScenes } =
    useValues(sceneLogic);
  const { showingDelayedSpinner } = useValues(appLogic);

  let sceneElement: ReactNode;
  if (scene && scene in loadedScenes) {
    const { component: SceneComponent, paramsToProps } = loadedScenes[scene];
    const componentProps = paramsToProps?.(sceneParams) ?? {};

    sceneElement = <SceneComponent {...componentProps} />;
  } else {
    sceneElement = <SpinnerOverlay visible={showingDelayedSpinner} />;
  }

  return (
    <ErrorBoundary>
      {activeLoadedScene?.logic ? (
        <BindLogic
          logic={activeLoadedScene.logic}
          props={activeLoadedScene.paramsToProps?.(sceneParams) || {}}
        >
          {sceneElement}
        </BindLogic>
      ) : (
        sceneElement
      )}
    </ErrorBoundary>
  );
}

export default App;
