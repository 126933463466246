import { IS_DEV } from '@/helpers/constants';

const VITE_COOKIEBOT_ID = import.meta.env.VITE_COOKIEBOT_ID;

interface InitializeCookieBotProps {
  language?: string;
}

export interface CookieConsent {
  canTrack: boolean;
  submit: (props: {
    optinPreferences: boolean;
    optinStatistics: boolean;
    optinMarketing: boolean;
  }) => void;
}

export function setAndGetCookies(): CookieConsent {
  const flags = Object.assign(
    {},
    {
      necessary: window.Cookiebot?.consent?.necessary ?? true,
      marketing: window.Cookiebot?.consent?.marketing ?? false,
      consented: window.Cookiebot?.consented ?? true,
      declined: window.Cookiebot?.declined ?? false,
      hasResponse: window.Cookiebot?.hasResponse ?? false,
    }
  );

  return {
    canTrack: IS_DEV
      ? true
      : flags.consented &&
        (flags.marketing || flags.necessary) &&
        !flags.declined,
    submit: ({ optinPreferences, optinStatistics, optinMarketing }) => {
      window.Cookiebot?.submitCustomConsent(
        optinPreferences,
        optinStatistics,
        optinMarketing
      );
    },
  };
}

export function initializeCookieBot({ language }: InitializeCookieBotProps) {
  if (typeof window === 'undefined' || !VITE_COOKIEBOT_ID || !document) {
    return null;
  }
  if (VITE_COOKIEBOT_ID.length !== 36) {
    console.warn(
      `The cookie bot domain group id is ${VITE_COOKIEBOT_ID.length} characters, instead it should be 36 characters!`
    );
  }

  if (!document.getElementById('CookieBot')) {
    const script = document.createElement('script');
    script.setAttribute('id', 'CookieBot');
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    script.setAttribute('data-cbid', VITE_COOKIEBOT_ID);
    script.setAttribute('data-blockingmode', 'auto');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');
    if (language) {
      script.setAttribute('data-culture', language);
    }
    document.head.appendChild(script);

    window.addEventListener('CookiebotOnDialogInit', () => {
      // Cookiebot overwrites document.createElement and overrides event listeners which breaks React
      document.createElement =
        // @ts-ignore
        document.createElementOrig || document.createElement;
      window.Cookiebot?.stopOverrideEventListeners?.();
    });

    window.addEventListener(
      'CookiebotOnDialogDisplay',
      () => {
        window.Cookiebot?.hide?.();
      },
      false
    );
  }
}

export default setAndGetCookies();
